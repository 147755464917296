<template>
  <HrdCloudMobileHeader :show-back="true" :show-search="false" title="학습 로드맵">
  </HrdCloudMobileHeader>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <article class="content-section">
        <div class="section-data">
          <span class="title">현재직무:</span><span v-if="lastJobInfo">{{lastJobInfo.jobNm}}</span>
        </div>
        <div class="section-data mb-2">
          <span class="title">AI가 추천하는 직무(영업점):</span><span v-if="aiResult.length > 0">{{aiResult[0].dtyNm}}</span>
        </div>
        <div class="section-data">
          <span class="title">UpSkill 추천로드맵:</span>
          <template v-if="upSkill">
            <span v-for="(data,idx) in upSkill" :key="idx" class="fw-bold text-red">
              {{data}}<span v-if="idx+1 < upSkill.length" class="me-1">,</span>
            </span>
          </template>
          <span v-else>-</span>
        </div>
        <div class="section-data">
          <span class="title">ReSkill 추천로드맵:</span>
          <template v-if="reSkill">
            <span v-for="(data,idx) in reSkill" :key="idx" class="fw-bold text-primary">
              {{data}}<span v-if="idx+1 < reSkill.length" class="me-1">,</span>
            </span>
          </template>
          <span v-else>-</span>
        </div>
      </article>
      <article class="content-section">
        <div class="section-data">
          <span class="title">AI 직무적합도 분석</span>
          <div class="ai-result-area">
            <div class="info">
              <span>나의 정보 데이터를 기반으로 분석한 직무적합도입니다.</span><br>
              <span>※ AI적합률 100~85%: 적극추천 / 85~70%: 추천 / 70~60%: 적합 / 60% 이하: 보통</span>
            </div>
            <div class="d-flex flex-wrap justify-content-around my-2 w-100">
              <div class="ai-result" v-for="(item,idx) in aiResult" :key="idx">
                <span class="text">{{item.dtyNm}}</span>
                <div class="sub-info">
                  <div class="text">{{item.dtyScr}}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-data" v-if="false">
          <span class="title">AI가 본 나를 표현하는 키워드</span>
          <div class="mt-2">
            <span class="me-2 text-primary fw-bold">#HRD 클라우드</span>
            <span class="me-2 text-primary fw-bold">#자산관리</span>
          </div>
        </div>
      </article>
      <article class="content-section">
        <div class="section-header">
          <div class="title"></div>
          <div class="header-side">
            <button class="kb-btn kb-btn-secondary kb-btn-collapse kb-collapse-toggle" @click="showAllList = !showAllList">
              <span class="text">{{showAllList ? '접기' : '펼치기'}}</span><i class="icon-arrow" :class="{'rotate': showAllList}" />
            </button>
          </div>
        </div>
        <div class="map-container">
          <ul class="map-list" data-depth="1">
            <li class="map-item" :class="{'is-active': cate.isActive}" v-for="cate in nodes" :key="cate.hrdCloudCateSn">
              <div class="item-box" :class="{'is-active': cate.isActive}" :cate-sn="cate.hrdCloudCateSn">
                <span class="title text-skip">{{cate.hrdCloudCateNm}}</span>
                <div class="d-flex align-items-center">
                  <div v-if="cate.isCompleted" class="badge"><span class="badge-text">완료</span></div>
                  <div class="box-column" @click.stop="cate.isActive = !cate.isActive"><i class="icon-mark ms-1" /></div>
                </div>
              </div>
              <ul class="map-list" data-depth="2" v-if="cate.isActive">
                <li class="map-item" :class="{'is-active': level.isActive}" v-for="level in cate.levels" :key="level.hrdCloudCateLevel">
                  <div class="item-box" :class="{'is-active': level.isActive}" :cate-sn="cate.hrdCloudCateSn">
                    <span class="title text-skip" @click.stop="moveToDtl($router, cate.hrdCloudCateSn, level.hrdCloudCateLevel)">{{level.roadmapTitle}}</span>
                    <div class="d-flex align-items-center">
                      <div v-if="level.isCompleted" class="badge"><span class="badge-text">완료</span></div>
                      <template v-else-if="level.upskillRcmdYn == 'Y' || level.reskillRcmdYn == 'Y'">
                        <div>
                          <div v-if="level.upskillRcmdYn == 'Y'" class="badge badge-red"><span class="badge-text">UpSkill추천</span></div>
                          <div v-if="level.reskillRcmdYn == 'Y'" class="badge badge-primary"><span class="badge-text">ReSkill추천</span></div>
                        </div>
                      </template>
                      <div class="box-column" @click.stop="toggleLevel(level)"><i class="icon-mark ms-1" /></div>
                    </div>
                  </div>
                  <ul class="map-list" data-depth="3" v-if="level.isActive && level.cttList.length > 0">
                    <li class="map-item" v-for="item in level.cttList" :key="item.cttMstSn" @click.stop="moveToContentBridge($router, item.lrnObjDtlDistSn)">
                      <div class="item-box" :cate-sn="cate.hrdCloudCateSn">
                        <span class="title text-skip">{{item.objNm}}</span>
                        <div v-if="item.cmptnYn == 'Y'" class="badge"><span class="badge-text">완료</span></div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </article>
      <article class="content-section">
        <HrdCloudVisitor />
      </article>
    </div>
  </main>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import HrdCloudVisitor from '@/components/hrdcloud/HrdCloudVisitor';
import {hrdCloudRoadmapMainSetup, moveToDtl} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";

export default {
  name: 'MobileHrdCloudRoadmapMain',
  methods: {moveToDtl},
  components: {HrdCloudMobileHeader, HrdCloudVisitor},
  setup: hrdCloudRoadmapMainSetup
}
</script>

<style>
.section-header .header-side .kb-btn-collapse {width: 125px; height: 34px; justify-content: space-between; padding: 0 20px;}
.map-container .map-list .map-item .item-box .badge {min-width: 2.4rem;}
.map-container .map-list .map-item .item-box .badge .badge-text {word-break: keep-all;}
.map-container .map-list[data-depth='1'] .map-item .item-box {background-color: rgba(114,0,255,.15) !important;}
.map-container .map-list[data-depth='2'] .map-item .item-box {background-color: rgba(255,188,0,.2) !important;}
.map-container .map-list[data-depth='3'] .map-item .item-box {background-color: #FBF9F7 !important;}
</style>